import { Card, Group, Text } from '@mantine/core'

import { CopyWidgetButton } from './copy-widget-button'
import { DisplayAmount, formattedAmount } from './DisplayAmount'

export const PipelineStatus = ({
  currency,
  pipelineStatusData,
  isLoading,
}: {
  pipelineStatusData:
    | {
        mql: number
        sql: number
        qualified: number
        currentARR: number
      }
    | undefined
  isLoading: boolean
  currency: string
}) => {
  const { targetRef, copyButton } = CopyWidgetButton({ title: 'Pipeline Status' })

  return (
    <Card
      shadow='sm'
      radius='md'
      h='100%'
      withBorder
      c='#868e96'
      ref={targetRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Group position='apart'>
        <Text fw={600}>PIPELINE STATUS:</Text>
        {copyButton}
      </Group>
      <Text fz='sm'>MONTH 4 OF 12 MONTHS PROGRAM</Text>
      <div
        style={{
          padding: 10,
        }}
      >
        <div>
          <Text>MQL Stage</Text>
          <Text fw={600} c={'#000'}>
            <Group>
              {currency} {formattedAmount(pipelineStatusData?.mql, isLoading)}
            </Group>
          </Text>
        </div>
        <div
          style={{
            paddingTop: 10,
          }}
        >
          <Text>SQL Stage</Text>
          <Text fw={600} c={'#000'}>
            <Group>
              {currency} {formattedAmount(pipelineStatusData?.sql, isLoading)}
            </Group>
          </Text>
        </div>
        <div
          style={{
            paddingTop: 10,
          }}
        >
          <Text>Qualified</Text>
          <Text fw={600} c={'#000'}>
            <Group>
              {currency}
              {formattedAmount(pipelineStatusData?.qualified, isLoading)}
            </Group>
          </Text>
        </div>
      </div>
      <DisplayAmount
        amount={pipelineStatusData?.currentARR}
        isLoading={isLoading}
        currency={currency}
        label='Total current pipeline'
      />
    </Card>
  )
}
