import { useState } from 'react'
import { createStyles, Stack, Text } from '@mantine/core'

const useStyles = createStyles(() => ({
  companyLogoText: {
    textWrap: 'wrap',
    lineHeight: '1.1',
  },
  image: {
    width: 40,
    height: 40,
    objectFit: 'contain',
    textIndent: -10000,
  },
}))

export const CompanyLogo = ({ domain, companyName }: { domain: string; companyName?: string }) => {
  const { classes } = useStyles()
  const logoUrl = `https://${process.env.REACT_APP_LOGO_URL}/${domain}.png`
  const [showLogo, setShowLogo] = useState(true)

  return (
    <Stack justify='center' align='center' spacing='0'>
      <img
        className={classes.image}
        src={logoUrl}
        alt={companyName}
        onLoad={() => setShowLogo(true)}
        style={{ display: showLogo ? 'block' : 'none' }}
        onError={(e) => {
          setShowLogo(false)
        }}
      />

      {companyName && (
        <Text
          ta='center'
          pt={logoUrl ? 2 : 0}
          size={logoUrl ? 'xs' : 'md'}
          className={classes.companyLogoText}
        >
          {companyName}
        </Text>
      )}
    </Stack>
  )
}
