import { Card, Group, Stack, Text } from '@mantine/core'

import { CopyWidgetButton } from './copy-widget-button'
import { DisplayAmount } from './DisplayAmount'

export const RevenueForecast = ({
  currency,
  pipelineStatusData,
  isLoading,
}: {
  pipelineStatusData:
    | {
        forecastARR: number
        forecastLTV: number
      }
    | undefined
  isLoading: boolean
  currency: string
}) => {
  const { targetRef, copyButton } = CopyWidgetButton({ title: 'Pipeline Status' })
  return (
    <Card
      shadow='sm'
      radius='md'
      withBorder
      c='#868e96'
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      ref={targetRef}
    >
      <Group position='apart'>
        <Text fw={600}>REVENUE FORECAST:</Text>
        {copyButton}
      </Group>
      <Stack
        justify='space-between'
        style={{
          flex: 1,
          paddingTop: 10,
        }}
      >
        {[
          {
            label: 'ARR Forecast',
            value: pipelineStatusData?.forecastARR || 0,
          },
          {
            label: 'LTV Forecast',
            value: pipelineStatusData?.forecastLTV || 0,
          },
        ].map(({ label, value }) => (
          <DisplayAmount
            key={label}
            isLoading={isLoading}
            label={label}
            amount={value}
            currency={currency}
          />
        ))}
      </Stack>
    </Card>
  )
}
