import { useCallback, useEffect, useRef, useState } from 'react'
import { useNotificationContext } from '@ospace/shared'
import { IconCopy, IconCopyCheck } from '@tabler/icons-react'
import { toBlob } from 'html-to-image'

export const useCopyWidget = (title: string) => {
  const { setNotification, notification } = useNotificationContext()
  const ref = useRef<HTMLDivElement>(null)
  const [copying, setCopying] = useState(false)

  const [copySuccess, setCopySuccess] = useState(false)

  const copyHandler = useCallback(() => {
    if (ref.current === null) {
      return
    }
    setCopying(true)
    toBlob(ref.current, { cacheBust: true, backgroundColor: 'white', type: 'image/png' })
      .then((blob) => {
        if (!blob) {
          setNotification({
            active: true,
            type: 'danger',
            message: `Copy failed`,
          })
          setCopying(false)
          return
        }
        navigator.clipboard.write([
          new ClipboardItem({
            'image/png': blob,
          }),
        ])
        setNotification({
          active: true,
          type: 'success',
          message: `Copied ${title.toUpperCase()} to clipboard`,
        })
        setCopySuccess(true)
        setCopying(false)
      })
      .catch((err) => {
        console.error(err)
        setCopying(false)
        setNotification({
          active: true,
          type: 'danger',
          message: `Copy failed`,
        })
      })
  }, [setNotification, title])
  const CopyIcon = copySuccess ? IconCopyCheck : IconCopy

  useEffect(() => {
    if (!notification) {
      setCopySuccess(false)
    }
  }, [notification])

  return { targetRef: ref, copyHandler, CopyIcon, copySuccess, copying }
}
