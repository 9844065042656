import * as React from 'react'
import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { AppShell, Box, Center, createStyles, Header, Loader, Stack } from '@mantine/core'
import { useUserRole } from '@ospace/auth'
import { useCampaign } from '@ospace/campaign'
import { Notification } from '@ospace/shared'
import { useUser } from '@ospace/user'

import { useSideNav } from '../../hooks/useSideNav'
import { CustomHeader } from '../CustomHeader'
import { Footer } from '../Footer'
import { PageId, SideNavbar } from '../SideNavbar'

import ProgramHeader from './ProgramHeader'
import ProgramNavigation from './ProgramNavigation'

const useStyles = createStyles((theme) => ({
  main: {
    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
  },
  mainContainer: {
    margin: -12,
    height: '100%',
  },
  innerPage: {
    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
  },
}))

const EmptyHeader = (
  <Header height={0}>
    <></>
  </Header>
)

type Props =
  | {
      status: 'ready'
      pageId: PageId
      children: ReactElement
      subHeader?: ReactElement
      pageTitle?: string
      fluid?: boolean
    }
  | {
      status: 'loading'
      pageId: PageId
      pageTitle?: string
      subHeader?: ReactElement
      fluid?: boolean
    }
  | {
      status: 'error'
      pageId: PageId
      pageTitle?: string
      subHeader?: ReactElement
      fluid?: boolean
      message: string
    }

export const ProgramLayout = (props: Props) => {
  const role = useUserRole()
  const user = useUser()
  const params: any = useParams()
  const programId = Number(params?.id)

  const { data: campaign, isSuccess } = useCampaign(programId)

  const { classes } = useStyles()
  const { opened, setOpened } = useSideNav()

  if (user.status !== 'success' || !isSuccess) {
    return (
      <Center maw='100vh' h='100vh' mx='auto'>
        <Loader variant='bars' />
      </Center>
    )
  }

  return (
    <>
      <Notification />
      <AppShell
        className={classes.main}
        navbar={
          <SideNavbar
            activePageId={props.pageId}
            setOpened={setOpened}
            opened={opened}
            user={user.data}
            role={role}
            client={campaign.client}
          />
        }
        header={EmptyHeader}
      >
        <Stack justify='space-between' h='100%'>
          <div className={classes.mainContainer}>
            <CustomHeader
              user={user.data}
              role={role}
              client={campaign.client || campaign.client}
              subHeader={<ProgramHeader campaign={campaign} />}
              pageTitle={campaign?.name || ''}
            />
            <ProgramNavigation campaign={campaign} />
            <Box
              className={classes.innerPage}
              maw={props.fluid ? '100%' : 1600}
              m='auto'
              px='xl'
              pb='xl'
            >
              {props.status === 'ready' ? props.children : null}
              {props.status === 'loading' ? (
                <Center maw='100vh' h='100vh' mx='auto'>
                  <Loader variant='bars' role='alert' aria-label='loading' />
                </Center>
              ) : null}
              {props.status === 'error' ? props.message : null}
            </Box>
          </div>
          <Footer />
        </Stack>
      </AppShell>
    </>
  )
}
