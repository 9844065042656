import { Card, createStyles, Grid, Group, Stack, Text } from '@mantine/core'

import { CopyWidgetButton } from '../copy-widget-button'

const useStyles = createStyles(() => ({
  highLightText: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  borderSpacer: {
    borderRight: '1px solid #868e96',
  },
  greenRateText: {
    color: '#1ec78c',
    paddingRight: 3,
  },
  blackText: {
    paddingTop: 2,
    color: '#000',
  },
}))

export interface OutreachWidgetProps {
  title: string
  leftTitle: string
  rightTitle: string
  data: {
    title: string
    value: number | string
    delta?: string
  }[]
}

export function OutreachWidget({ data, title, rightTitle, leftTitle }: OutreachWidgetProps) {
  const { classes, cx } = useStyles()
  const { targetRef, copyButton } = CopyWidgetButton({ title })
  return (
    <Card
      shadow='sm'
      radius='md'
      withBorder
      c='#868e96'
      ref={targetRef}
      h='100%'
      style={{
        minWidth: '450px',
      }}
    >
      <Group position='apart'>
        <Text className={classes.highLightText}>{title}</Text>
        {copyButton}
      </Group>
      <div
        style={{
          padding: '10px',
        }}
      >
        <Grid>
          <Grid.Col span={6} py='2px'>
            <Text className={classes.highLightText}>{rightTitle}</Text>
          </Grid.Col>
          <Grid.Col span={6} py='2px'>
            <Text className={classes.highLightText}>{leftTitle}</Text>
          </Grid.Col>
        </Grid>
        <Grid
          style={{
            lineHeight: '1.1',
          }}
        >
          {data.map((item, index) => (
            <Grid.Col span={3} key={index}>
              <Stack spacing={0} h='100%'>
                <Stack
                  className={index % 2 === 0 ? classes.borderSpacer : ''}
                  h='100%'
                  justify='space-between'
                >
                  <div>
                    <div>{item.title}</div>
                    <div className={cx(classes.highLightText, classes.blackText)}>{item.value}</div>
                  </div>
                  {item.delta && <div className={classes.greenRateText}>{item.delta}</div>}
                </Stack>
              </Stack>
            </Grid.Col>
          ))}
        </Grid>
      </div>
    </Card>
  )
}
