import React from 'react'
import { Button } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'

import { ContactTable } from './ContactTable'

const ContactReviewTable: React.FC<any> = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { contacts, payload, mutation } = props
  const [clicked, setClicked] = React.useState(false)
  const onUpload = async () => {
    setClicked(true)
    const { importId } = await mutation.mutateAsync(payload as any)
    navigate(`${location.pathname.replace(/review/, '')}upload/${importId}`)
  }
  const hasAnyErrors = contacts?.some((contact: any) => contact.validationErrors.length)

  return (
    <div className={`card`}>
      <ContactTable contacts={contacts} title='Review CSV import' />

      <div className='card-footer'>
        <button
          type='submit'
          className='btn btn-primary'
          onClick={onUpload}
          disabled={hasAnyErrors || clicked}
        >
          {mutation.isLoading && (
            <span className='spinner-border spinner-border-sm align-middle mx-2' />
          )}
          Continue
        </button>
        <Button onClick={() => navigate(-1)} className='btn btn-secondary mx-10'>
          Cancel & Re-upload CSV
        </Button>
      </div>
    </div>
  )
}

export { ContactReviewTable }
