import { ActionIcon } from '@mantine/core'

import { useCopyWidget } from './useCopyWidget'

export const CopyWidgetButton = ({ title }: { title: string }) => {
  const { targetRef, copySuccess, copying, CopyIcon, copyHandler } = useCopyWidget(title)

  return {
    copyButton: (
      <ActionIcon
        color={copySuccess ? 'green' : 'gray'}
        style={{
          opacity: copying ? 0 : 1,
        }}
      >
        <CopyIcon size='1.125rem' onClick={copyHandler} />
      </ActionIcon>
    ),
    targetRef,
  }
}
