/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { useUserRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { DropdownLink, Metrics } from '@ospace/shared'

import { formatDate } from '../../utils'
import { CopyToClipboardBtn } from '../CopyToClipboardButton'

export const CampaignHeader: React.FC<any> = (props: any) => {
  const { campaign } = props
  const role: any = useUserRole()
  const meetingsBooked =
    campaign?.objective?.results.reduce(
      (a: any, c: any) => a + Number(c.digitalProspectingMeetingBooked) + Number(c.meetingBooked),
      0
    ) || 0

  const dataAndIntelligenceItems = [
    {
      name: 'Contacts',
      link: `/campaign/${campaign.id}/contact`,
    },
    {
      name: 'Accounts',
      link: `/campaign/${campaign.id}/company`,
    },
    {},
    { name: 'Analytics Dashboards' },
    ...(campaign?.objective?.isIntentsEnabled
      ? [
          {
            name: 'Intent Data',
            link: `/campaign/data-intel/${campaign.id}/dashboard/intent`,
          },
          {
            name: 'Intent and Engagement',
            link: `/campaign/data-intel/${campaign.id}/dashboard/intent_and_engagement`,
          },
        ]
      : []),
    ...(campaign?.objective?.isContactsDiscoveredEnabled
      ? [
          {
            name: 'Contact Data',
            link: `/campaign/data-intel/${campaign.id}/dashboard/contact`,
          },
        ]
      : []),
  ]

  return (
    <div className='card mb-6 mb-xl-9'>
      <div className='card-body pt-9 pb-0'>
        {/* begin::Details */}
        <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
          {/*begin::Image*/}
          <div className='d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4'>
            <img
              src={campaign.client?.signedLogoUrl}
              className=''
              alt='Campaign logo'
              style={{ width: '150px', paddingTop: '15px', objectFit: 'contain' }}
            />
          </div>
          {/*end::Image*/}
          {/*begin::Wrapper*/}
          <div className='flex-grow-1'>
            {/*begin::Head*/}
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              {/*begin::Details*/}
              <div className='d-flex flex-column'>
                {/*begin::Status*/}
                <div className='d-flex align-items-center mb-1'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-3'>
                    Campaign: {campaign.name}
                  </a>
                  <span className='badge badge-light-success me-auto'>{campaign?.status}</span>
                </div>
                {/*end::Status*/}
                {/*begin::Description*/}
                <div className='d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400'>
                  {campaign.description}
                </div>
                {/*end::Description*/}
                <div className='d-flex align-items-center mb-1'>
                  <p className='text-gray-700 fs-5 fw-bolder me-3'>
                    Start Date: {formatDate(campaign.startDate)}
                  </p>
                  {!!campaign.endDate && (
                    <>
                      <p className='text-gray-700 fs-5 fw-bolder me-3'>|</p>
                      <p className='text-gray-700 fs-5 fw-bolder me-3'>
                        End Date: {formatDate(campaign.endDate)}
                      </p>
                    </>
                  )}
                  <>
                    <p className='text-gray-700 fs-5 fw-bolder me-3'>|</p>
                    <p className='text-gray-700 fs-5 fw-bolder me-3'>
                      Program ID: {campaign.id} <CopyToClipboardBtn text={campaign.id} />
                    </p>
                  </>
                </div>
              </div>
              {/*end::Details*/}
              {/*begin::Actions*/}
              <div className='d-flex mb-4'>
                {(role === userTypes.Admin ||
                  role === userTypes.ClientManager ||
                  role === userTypes.CampaignManager) && (
                  <Link
                    to={`/campaign/${campaign.id}/objective/create`}
                    className='btn btn-sm btn-primary me-3'
                  >
                    Update Campaign Performance
                  </Link>
                )}
              </div>
              {/*end::Actions*/}
            </div>
            {/*end::Head*/}
            {/*begin::Info*/}
            <div className='d-flex flex-wrap justify-content-start'>
              {/*begin::Stats*/}
              <div className='d-flex flex-wrap'>
                {/*begin::Stat*/}
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  {/*begin::Number*/}
                  <div className='d-flex align-items-center'>
                    <div className='fs-4 fw-bolder'>{meetingsBooked}</div>
                  </div>
                  {/*end::Number*/}
                  {/*begin::Label*/}
                  <div className='fw-bold fs-6 text-gray-400'>{Metrics.meetingBooked}</div>
                  {/*end::Label*/}
                </div>
                {/*end::Stat*/}
              </div>
              {/*end::Stats*/}
            </div>
            {/*end::Info*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Details*/}
        <div className='separator'></div>
        {/*begin::Nav wrapper*/}

        <div className='d-flex h-55px'>
          {/*begin::Nav links*/}
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {/*begin::Nav item*/}
            <li className='nav-item'>
              <Link
                className='nav-link text-active-primary me-6'
                to={`/campaign/view/${campaign.id}`}
              >
                Overview
              </Link>
              <Link
                className='nav-link text-active-primary me-10 position-relative'
                to={`/program/view/${campaign.id}`}
              >
                Opportunity Dashboard
                <span
                  className='position-absolute top-0 start-100 m-2 badge badge-danger badge-sm'
                  style={{
                    padding: '0.25rem 0.4rem',
                    transform: 'rotate(5deg) translate(-25px, -2px)',
                    borderRadius: '0.2rem',
                  }}
                >
                  Beta
                </span>
              </Link>
            </li>

            {/*begin::Nav item*/}
            <DropdownLink title='Data and Intelligence' items={dataAndIntelligenceItems} />
            {/*end::Nav item*/}
            <li className='nav-item'>
              <Link
                className='nav-link text-active-primary me-6'
                to={`/campaign/${campaign.id}/files`}
              >
                Files
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link text-active-primary me-6'
                to={`/campaign/${campaign.id}/potential-opportunities`}
              >
                Potential Opportunities
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link text-active-primary me-6'
                to={`/campaign/${campaign.id}/account-insights`}
              >
                Account Insights
              </Link>
            </li>
          </ul>
          {/*end::Nav links*/}
        </div>
        {/*end::Nav wrapper*/}
      </div>
    </div>
  )
}
