import { OutreachWidget } from './OutreachWidget'

interface Props {
  emailsSent: number
  openRate: string
  requests: number
  accepted: number
}

const titleMap = {
  emailsSent: 'Emails Sent',
  openRate: 'Open Rate',
  requests: 'Requests',
  accepted: 'Accepted',
} as Record<string, string>

export function MultiChannelWidget(props: Props) {
  const data = Object.entries(props).map(([key, value]) => {
    return { value, title: titleMap[key] }
  })
  return (
    <OutreachWidget
      data={data}
      title='MULTI CHANNEL OUTREACH'
      rightTitle='EMAIL'
      leftTitle='LINKEDIN CONNECTIONS'
    />
  )
}
