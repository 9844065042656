import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotificationContext } from '@ospace/shared'
import * as Auth from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'

import { AuthLayout } from '../components/AuthLayout'
import { UserService } from '../services/user'

export function TokenPage() {
  const navigate = useNavigate()
  const { setNotification } = useNotificationContext()

  useEffect(() => {
    const handleSuccess = () => {
      setNotification({
        active: true,
        type: 'success',
        message: 'You have successfully logged in.',
      })
    }

    const handleError = () => {
      setNotification({
        active: true,
        type: 'error',
        message: 'Something went wrong with SSO.',
      })
    }
    Hub.listen('auth', async ({ payload: { event, data } }: any) => {
      if (event === 'signInWithRedirect') {
        const signInUserSession = await Auth.fetchAuthSession()

        const payLoad: any = {
          identityId: signInUserSession?.tokens?.idToken?.payload['cognito:username'],
          userGroups: signInUserSession?.tokens?.idToken?.payload['custom:usergroups'],
          firstName: signInUserSession?.tokens?.idToken?.payload?.given_name,
          lastName: signInUserSession?.tokens?.idToken?.payload?.family_name,
        }

        // Attempt to create user or just login
        const res = await UserService.createSSOUser(payLoad)
        /*
          Response 201 shows user have been created so redirect to SSO info page 
          User in this login session is not assigned to group yet, so they need to logout and login back
          Reponse 200 shows user already exits so redirect to homepage
        */
        if (res === 201) {
          handleSuccess()
          navigate('/sso', { replace: true })
        } else if (res === 200) {
          handleSuccess()
        } else {
          handleError()
          navigate('/auth/login', { replace: true })
        }
      }

      //handles redirect after SSO login to the page that user was trying to access
      if (event === 'customOAuthState') {
        const redirectPath = data
        navigate(redirectPath)
      }
    })
  }, [navigate, setNotification])

  return <AuthLayout>Logging in ....</AuthLayout>
}
