import { useParams } from 'react-router-dom'
import { Box, Center, Flex, Grid, Loader } from '@mantine/core'
import { useUserRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { ProgramLayout } from '@ospace/layout/components/ProgramLayout'
import { ErrorBoundary, useSearchParams } from '@ospace/shared'

import {
  ContactsDiscoveryCompaniesCard,
  ContactsDiscoveryContactsCard,
} from '../../components/ContactsDiscoveryCard'
import { MultiChannelWidget, TeleSalesWidget } from '../../components/outreach-widget'
import OpportunitiesTable from '../../components/partials/dashboard/OpportunitiesTable'
import { PipelineStatus } from '../../components/PipelineStatus'
import { RevenueForecast } from '../../components/RevenueForecast'
import { useDeals, useMCOstats, useOpportunityStats, usePipelineStatus } from '../../hooks'

type Props = { campaignId: number }

export const NewOverviewPage = (props: Props) => {
  const searchParams = useSearchParams()
  const showWidgets = searchParams.get('showWidgets')

  const deals = useDeals(props.campaignId)

  const role = useUserRole()
  const dealStats = useOpportunityStats(props.campaignId, !!showWidgets)
  const mcoStats = useMCOstats(props.campaignId, !!showWidgets)

  const { isLoading, data: pipelineStatusData } = usePipelineStatus(props.campaignId, !!showWidgets)
  return (
    <ProgramLayout status={deals.isFetching ? 'loading' : 'ready'} pageId='programs' fluid={true}>
      <Box mt='xl'>
        {role === userTypes.Admin && showWidgets ? (
          <Flex gap='sm' align='stretch' pt='sm' pb='sm'>
            <Grid w='100%'>
              <Grid.Col span={6}>
                <Grid>
                  <Grid.Col span={6}>
                    <RevenueForecast
                      pipelineStatusData={pipelineStatusData}
                      isLoading={isLoading}
                      //@TODO: fetch currency from client
                      currency={'AUD'}
                    />
                  </Grid.Col>
                  <Grid.Col span={6} h='100%'>
                    <PipelineStatus
                      pipelineStatusData={pipelineStatusData}
                      isLoading={isLoading}
                      //@TODO: fetch currency from client
                      currency={'AUD'}
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col
                span={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Flex
                  gap='sm'
                  align='stretch'
                  style={{
                    flexBasis: '50%',
                  }}
                >
                  <div
                    style={{
                      flexBasis: '80%',
                      // paddingTop: 'sm',
                    }}
                  >
                    {mcoStats.isFetching ? (
                      <Center h='100%' w='100%'>
                        <Loader />
                      </Center>
                    ) : (
                      <MultiChannelWidget
                        emailsSent={(mcoStats.data as any).total_emails_sent || 0}
                        openRate={`${(mcoStats.data as any).emails_open_rate || 0} %`}
                        requests={(mcoStats.data as any).total_linkedin_requests_sent || 0}
                        accepted={(mcoStats.data as any).total_linkedin_requests_accepted || 0}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      flexBasis: '20%',
                    }}
                  >
                    {dealStats.isFetching ? (
                      <Center h='100%' w='100%'>
                        <Loader />
                      </Center>
                    ) : (
                      <ContactsDiscoveryCompaniesCard
                        companies={dealStats.data.discovery['companies']}
                        rateOfChange={
                          dealStats.data.discoveryThisWeek['companies']
                            ? `+ ${dealStats.data.discoveryThisWeek['companies']}`
                            : ``
                        }
                      />
                    )}
                  </div>
                </Flex>
                <Flex
                  gap='sm'
                  align='stretch'
                  style={{
                    flexBasis: '50%',
                  }}
                >
                  <div
                    style={{
                      flexBasis: '80%',
                      paddingTop: '0.75rem',
                    }}
                  >
                    {dealStats.isFetching ? (
                      <Center h='100%' w='100%'>
                        <Loader />
                      </Center>
                    ) : (
                      <TeleSalesWidget
                        htn={{
                          value: dealStats.data?.teleSalesOutreach['HTN'],
                          delta: !!dealStats.data?.telseSalesOutreachThisWeek['HTN']
                            ? `+ ${dealStats.data?.telseSalesOutreachThisWeek['HTN']} this week`
                            : ``,
                        }}
                        ltn={{
                          value: dealStats.data?.teleSalesOutreach['LTN'],
                          delta: !!dealStats.data?.telseSalesOutreachThisWeek['LTN']
                            ? `+ ${dealStats.data?.telseSalesOutreachThisWeek['LTN']} this week`
                            : ``,
                        }}
                        meetingsBooked={{
                          value: dealStats.data?.teleSalesOutreach['Meetings Booked'],
                          delta: !!dealStats.data?.telseSalesOutreachThisWeek['Meetings Booked']
                            ? `+ ${dealStats.data?.telseSalesOutreachThisWeek['Meetings Booked']} this week`
                            : ``,
                        }}
                        requestedCallbacks={{
                          value: dealStats.data?.teleSalesOutreach['Call Back'],
                          delta: !!dealStats.data?.telseSalesOutreachThisWeek['Call Back']
                            ? `+ ${dealStats.data?.telseSalesOutreachThisWeek['Call Back']} this week`
                            : ``,
                        }}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      flexBasis: '20%',
                    }}
                  >
                    {dealStats.isFetching ? (
                      <Center h='100%' w='100%'>
                        <Loader />
                      </Center>
                    ) : (
                      <ContactsDiscoveryContactsCard
                        contacts={dealStats.data.discovery['contacts']}
                        rateOfChange={
                          dealStats.data.discoveryThisWeek['contacts'] !== '0'
                            ? `+ ${dealStats.data.discoveryThisWeek['contacts']}`
                            : ``
                        }
                      />
                    )}
                  </div>
                </Flex>
              </Grid.Col>
            </Grid>
          </Flex>
        ) : (
          <> </>
        )}
        <OpportunitiesTable deals={deals} />
      </Box>
    </ProgramLayout>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)

  return <NewOverviewPage campaignId={campaignId} />
}

export const NewOverviewPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
