import { FC } from 'react'
import { NavDropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

type DropdownItem = {
  name: string
  link: string
}

type DropdownLinkProps = {
  title: string
  items: DropdownItem[]
}
export const DropdownLink: FC<any> = (props: DropdownLinkProps) => {
  const { items, title } = props
  const navigate = useNavigate()
  return (
    <NavDropdown title={title} style={{ marginLeft: -15 }}>
      {items.map((item: DropdownItem, index: number) =>
        item.name ? (
          item.link ? (
            <NavDropdown.Item className='px-3 py-2' key={index} onClick={() => navigate(item.link)}>
              <span className='text-dark fw-bold text-capitalize menu-link'>{item.name}</span>
            </NavDropdown.Item>
          ) : (
            <NavDropdown.Header className='p-2' key={index}>
              {item.name}
            </NavDropdown.Header>
          )
        ) : (
          <NavDropdown.Divider key={index} />
        )
      )}
    </NavDropdown>
  )
}
