import { FC } from 'react'
import { Group } from '@mantine/core'
import { Input } from '@ospace/shared'

interface Props {
  title: string
}

export const StepPipelineParameters: FC<Props> = ({ title }) => {
  const fields = [
    { fieldName: 'emailRate', label: 'Email conversion rate', postfix: '%' },
    { fieldName: 'linkedinRate', label: 'LinkedIn conversion rate', postfix: '%' },
    { fieldName: 'noBudgetRate', label: 'Budget not obtained conversion rate', postfix: '%' },
    { fieldName: 'ltnRate', label: 'LTN conversion rate', postfix: '%' },
    { fieldName: 'htnRate', label: 'HTN conversion rate', postfix: '%' },
    { fieldName: 'BANTRate', label: 'BANT conversion rate', postfix: '%' },
    { fieldName: 'averageDealSize', label: 'Average deal size', postfix: '' },
    {
      fieldName: 'averageCustomerTermInYears',
      label: 'Average customer term in years',
      postfix: '',
    },
    { fieldName: 'programTermInMonths', label: 'Program term in months', postfix: '' },
  ]

  return (
    <div className='w-100'>
      <div className='fv-row mb-10 d-flex flex-stack'>
        <h3 className='stepper-title'>{title}</h3>
      </div>

      <div className='fv-row'>
        {fields.map((field) => (
          <div className='mb-10 d-flex' key={field.fieldName}>
            <Group spacing='xs'>
              <Input
                labelStyle={{ width: 200 }}
                inputStyle={{ width: 100 }}
                type='number'
                labelAlignment='left'
                {...field}
              />
              {field.postfix}
            </Group>
          </div>
        ))}
      </div>
    </div>
  )
}
