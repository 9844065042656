import { Card, Stack, Text } from '@mantine/core'

export const ContactsDiscoveryCompaniesCard = ({
  companies,
  rateOfChange,
}: {
  companies: number
  rateOfChange: string
}) => {
  return (
    <Card
      shadow='sm'
      c='#868e96'
      radius={0}
      style={{
        height: '100%',
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
        borderLeft: '0.0625rem solid #dee2e6',
        borderRight: '0.0625rem solid #dee2e6',
        borderTop: '0.0625rem solid #dee2e6',
      }}
    >
      <Text weight={600}>CONTACTS DISCOVERY</Text>

      <Stack
        style={{
          padding: 10,
        }}
      >
        <Text>Companies</Text>
        <Text c='black' fz='xl' fw={600}>
          {companies}
        </Text>
      </Stack>
    </Card>
  )
}
export const ContactsDiscoveryContactsCard = ({
  contacts,
  rateOfChange,
}: {
  contacts: number
  rateOfChange: string
}) => {
  return (
    <Card
      shadow='sm'
      radius={0}
      c='#868e96'
      style={{
        height: '100%',
        borderBottomLeftRadius: '0.5rem',
        borderBottomRightRadius: '0.5rem',
        borderLeft: '0.0625rem solid #dee2e6',
        borderRight: '0.0625rem solid #dee2e6',
        borderBottom: '0.0625rem solid #dee2e6',
      }}
    >
      <div
        style={{
          border: '0.0625rem solid #dee2e6',
          marginTop: -13,
        }}
      ></div>
      <Stack
        style={{
          padding: 10,
        }}
      >
        <Text
          style={{
            paddingTop: 40,
          }}
        >
          Contacts
        </Text>
        <div>
          <Text c='black' fz='xl' fw={600}>
            {contacts}
          </Text>
          {rateOfChange && <div style={{ color: '#1ec78c' }}>{rateOfChange} this week</div>}
        </div>
      </Stack>
    </Card>
  )
}
