import { useEffect, useState } from 'react'
import {
  Box,
  Center,
  Col,
  Flex,
  Grid,
  Loader,
  Modal,
  Paper,
  ScrollArea,
  Table,
  Text,
} from '@mantine/core'
import { CompanyLogo } from '@ospace/shared'
import dayjs from 'dayjs'

import { useContactsAndNotes, useDealViewMutation } from '../../../hooks'

export const DealModal = ({
  opened,
  onClose,
  data,
}: {
  opened: boolean
  onClose: () => void
  data: any
}) => {
  const [viewDeals, setViewDeals] = useState<any>([])
  const contactsAndNotes = useContactsAndNotes(data?.dealId, data?.campaignId)
  const updateDealViewedDate = useDealViewMutation(data?.dealId, data?.campaignId)

  const contactData = contactsAndNotes.data?.contacts?.map((contact: any) => ({
    name: `${contact.contactData?.properties.firstname} ${contact.contactData?.properties.lastname}`,
    title: contact.contactData?.properties.jobtitle,
    company: contact.companyData?.properties.name,
    date: dayjs(contact.contactData?.createdAt).format('DD/MM/YYYY'),
    action: 'Call back',
    phone: contact.contactData?.properties.phone,
  }))

  const interactionData = contactsAndNotes.data?.notes?.map((note: any) => {
    const stripHtmlTags = (str: string) => {
      const tempElement = document.createElement('div') // Create a temporary DOM element
      tempElement.innerHTML = str // Set the HTML string
      return tempElement.textContent || tempElement.innerText // Get text content
    }

    const plainText = stripHtmlTags(note.body)
    return {
      date: note?.createdAt,
      content: plainText,
    }
  })

  const contactRows = contactData?.map((contact: any, index: number) => (
    <tr key={index} style={{ color: '#495057' }}>
      <td>{contact.name}</td>
      <td>{contact.title}</td>
      <td>{contact.company}</td>
      <td>{contact.date}</td>
      <td>{contact.action}</td>
      <td>{contact.phone}</td>
    </tr>
  ))
  const companyDomain = contactsAndNotes.data?.contacts?.[0]?.companyData?.properties.domain
  const companyIndustry = contactsAndNotes.data?.contacts?.[0]?.companyData?.properties.industry
  const contactEmail = contactsAndNotes.data?.contacts?.[0]?.contactData?.properties.email

  useEffect(() => {
    if (opened && data.dealId && !viewDeals.includes(data.dealId) && data.dealViewedAt === null) {
      updateDealViewedDate.mutate()
      setViewDeals((prev: any) => [...prev, data.dealId])
    }
    // eslint-disable-next-line
  }, [opened])

  return (
    <>
      <Modal opened={opened} onClose={onClose} size={1000}>
        <Modal.Header p={0} px={'md'}></Modal.Header>
        {contactsAndNotes.isSuccess ? (
          <Modal.Body mt={0}>
            <Grid>
              {companyDomain && (
                <Flex justify='center' direction='column'>
                  <CompanyLogo domain={companyDomain} />
                </Flex>
              )}
              <Col span={6}>
                <Text c={'gray.7'} size={'lg'}>
                  Company Name: {data.company}
                </Text>
                <Text c={'gray.7'} size={'lg'}>
                  Contact Name: {data.contact}
                </Text>
                <Text c={'gray.7'} size={'lg'}>
                  Position/Role: {data.role}
                </Text>
              </Col>
              <Col span={5}>
                {companyIndustry && (
                  <Text c={'gray.7'} size={'lg'}>
                    Industry: {companyIndustry}
                  </Text>
                )}
                {contactEmail && <Text>Email: {contactEmail}</Text>}
                <Text c={'gray.7'} size={'lg'}>
                  Date: {dayjs(data?.createdAt).format('DD/MM/YYYY')}
                </Text>
              </Col>
            </Grid>

            <>
              <Text size='md' mt='md' weight={500} tt={'uppercase'} c={'gray'} fw={'bold'}>
                Contacts
              </Text>

              <Table>
                <tbody>{contactRows}</tbody>
              </Table>

              {interactionData && interactionData.length > 0 && (
                <Paper>
                  <Text size='md' mt='md' weight={500} tt={'uppercase'} c={'gray'} fw={'bold'}>
                    Latest Interactions
                  </Text>
                  <ScrollArea pt={'md'} h={400}>
                    {interactionData?.map((interaction: any, index: number) => (
                      <>
                        <div
                          key={index}
                          style={{
                            marginBottom: '1rem',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                          }}
                        >
                          <Box
                            style={{
                              background: '#ddd',
                              width: '120px',
                              float: 'right',
                              textAlign: 'center',
                              borderRadius: '4px',
                            }}
                          >
                            <Text size='sm' color='gray' fw={900}>
                              {dayjs(interaction.date).format('DD/MM/YYYY')}
                            </Text>
                          </Box>
                          <Text p={'md'}>{interaction.content}</Text>
                        </div>
                      </>
                    ))}
                  </ScrollArea>
                </Paper>
              )}
            </>
          </Modal.Body>
        ) : (
          <Center>
            <Loader />
          </Center>
        )}
      </Modal>
    </>
  )
}
